import React from "react";
import { Link } from 'gatsby';
import Hours from "../hours";
import FixedImage from "../fixedImage";
import formatPhone from "../../helpers/formatPhone";
import styles from "./styles.module.scss";
import WPLink from "../wpLink";
// SVG
import arrowIconBrightOrange from '../../images/svg-icons/arrow-bright-orange.svg'
import arrowDiagonalGray from '../../images/svg-icons/diagonal-arrow-gray.svg'
import arrowDiagonalOrange from '../../images/svg-icons/diagonal-arrow-orange.svg'


const LocationCard = ({ location, arrowRedLocal, isLocationsPage = false, rightArrowGray = false, isTeamPage = false, isAppointmentsPage = false }) => {
	function directionLink() {
		if (!location?.birdeyeLocation.location.lat || !location.birdeyeLocation.location.lng) {
			return;
		}

		let placeIdParam = '';

		if (location.ACFLocation.googlePlaceId) {
			placeIdParam = `&destination_place_id=${location.ACFLocation.googlePlaceId}`;
		}
		return <a className={styles.directions} href={`https://www.google.com/maps/dir/?api=1&destination=${location.birdeyeLocation.location.lat}, ${location.birdeyeLocation.location.lng}${placeIdParam}`} target="_blank" rel="noopener noreferrer">Get Directions <img src={isLocationsPage ? arrowDiagonalOrange : arrowDiagonalGray} loading="lazy" alt="" /></a>;
	}

	return (
		<>
		{ location.ACFLocation.bookMyAppointmentLink && isAppointmentsPage ? 
		( 
			<Link to={location.ACFLocation.bookMyAppointmentLink.url} className={styles.cardlinkappts} target="_blank">
				<div className={`${isTeamPage ? styles.LocationCardTeamPage : styles.LocationCard} ${isLocationsPage && styles.locationspage} ${isAppointmentsPage && styles.apptspage}`}>
					<div className={styles.top}>
						{ !isAppointmentsPage && <Link to={location.link} className={styles.title}>{location.title}</Link> }
						{ isAppointmentsPage && <Link to={location.link} className={styles.titleappointments}>{location.title}</Link> }
						{ location.birdeyeLocation.hoursOfOperations && !isAppointmentsPage && <Hours locationHours={location.birdeyeLocation.hoursOfOperations} utcOffset={location.birdeyeLocation.timezone} /> } 
					</div>
					<div className={styles.bottom}>
						{ location.ACFLocation.briefDescription && <p dangerouslySetInnerHTML={{__html: location.ACFLocation.briefDescription.length > 100 ? location.ACFLocation.briefDescription.substring(0, 99) + "..." : location.ACFLocation.briefDescription}}></p>}
						<address>
							{ location.birdeyeLocation.location.address1 && <span className={styles.address} dangerouslySetInnerHTML={{__html: location.birdeyeLocation.location.address1 }}></span>}
							{ location.birdeyeLocation.location.address2 && <span className={`${styles.address} ${styles.addressline2}`} dangerouslySetInnerHTML={{__html: location.birdeyeLocation.location.address2 }}></span>}
							{ location.birdeyeLocation.location.zip && location.birdeyeLocation.location.state && location.birdeyeLocation.location.city && <span className={`${styles.address} ${styles.addressline3}`} dangerouslySetInnerHTML={{__html: `${location.birdeyeLocation.location.city}, ${location.birdeyeLocation.location.state} ${location.birdeyeLocation.location.zip}`}}></span>}
						</address>
						{ directionLink() }
						{ location.birdeyeLocation.phone && <a className={styles.phone} href={`tel:${location.birdeyeLocation.phone}`}>{formatPhone(location.birdeyeLocation.phone)}</a>}
						{ location.ACFLocation.bookMyAppointmentLink && !isAppointmentsPage && <WPLink link={location.ACFLocation.bookMyAppointmentLink} passedClass={styles.link} icon={arrowIconBrightOrange} iconIsSVG={true} />}
						{ location.ACFLocation.bookMyAppointmentLink && isAppointmentsPage && <Link to={location.ACFLocation.bookMyAppointmentLink.url} className={styles.linkappointments} target="_blank">Book now 
							{/*<FixedImage image={arrowRedLocal} passedClass={styles.iconhover} />*/} {/* rightArrowGray && <FixedImage image={rightArrowGray} passedClass={styles.icon} /> */}
							<svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 9 11">
								<g fill="none" fillRule="evenodd">
									<g fill="#FBB03B" className={styles.arrowfill}>
										<g>
											<g>
												<path d="M188.638 11.04l-2.44-2.452c-.336-.339-.882-.339-1.218 0-.337.339-.337.887 0 1.226l3.91 3.932c.04.04.085.076.132.108.02.013.04.022.06.033.03.016.058.034.089.047.025.01.05.016.077.024.028.009.055.02.084.025.044.009.089.012.133.013.012 0 .023.004.035.004.013 0 .026-.004.039-.004.043-.002.086-.004.129-.013.03-.006.059-.017.088-.027.025-.007.05-.013.073-.022.033-.014.064-.033.095-.05.018-.01.036-.018.053-.03.048-.032.093-.068.133-.109l3.91-3.931c.169-.17.253-.391.253-.613 0-.222-.084-.444-.253-.613-.336-.339-.882-.339-1.219 0l-2.439 2.453V5.867c0-.479-.386-.867-.862-.867s-.862.388-.862.867v5.174z" transform="translate(-535 -751) translate(35 45) translate(315 702) rotate(-90 189.5 9.5)"/>
											</g>
										</g>
									</g>
								</g>
							</svg>
 
						</Link>}
						{ !location.ACFLocation.bookMyAppointmentLink && location.birdeyeLocation.phone && !isAppointmentsPage && <a className={styles.link} href={`tel:${location.birdeyeLocation.phone}`}>Call Now</a>}
						{ !location.ACFLocation.bookMyAppointmentLink && location.birdeyeLocation.phone && isAppointmentsPage && <a className={styles.linkappointments} href={`tel:${location.birdeyeLocation.phone}`}>Call Now</a>}
					</div>
				</div>
			</Link>
		)
		: // don't wrap in Link
		(
			<div className={`${styles.cardlinknoappts} ${!isAppointmentsPage && !isLocationsPage && styles.nopadding}`}>
				<div className={`${isTeamPage ? styles.LocationCardTeamPage : styles.LocationCard} ${isLocationsPage && styles.locationspage} ${isAppointmentsPage && styles.apptspage}`}>
					<div className={styles.top}>
						{ !isAppointmentsPage && <Link to={location.link} className={styles.title}>{location.title}</Link> }
						{ isAppointmentsPage && <Link to={location.link} className={styles.titleappointments}>{location.title}</Link> }
						{ location.birdeyeLocation.hoursOfOperations && !isAppointmentsPage && <Hours locationHours={location.birdeyeLocation.hoursOfOperations} utcOffset={location.birdeyeLocation.timezone} /> } 
					</div>
					<div className={styles.bottom}>
						{ location.ACFLocation.briefDescription && <p dangerouslySetInnerHTML={{__html: location.ACFLocation.briefDescription.length > 100 ? location.ACFLocation.briefDescription.substring(0, 99) + "..." : location.ACFLocation.briefDescription}}></p>}
						<address>
							{ location.birdeyeLocation.location.address1 && <span className={styles.address} dangerouslySetInnerHTML={{__html: location.birdeyeLocation.location.address1 }}></span>}
							{ location.birdeyeLocation.location.address2 && <span className={`${styles.address} ${styles.addressline2}`} dangerouslySetInnerHTML={{__html: location.birdeyeLocation.location.address2 }}></span>}
							{ location.birdeyeLocation.location.zip && location.birdeyeLocation.location.state && location.birdeyeLocation.location.city && <span className={`${styles.address} ${styles.addressline3}`} dangerouslySetInnerHTML={{__html: `${location.birdeyeLocation.location.city}, ${location.birdeyeLocation.location.state} ${location.birdeyeLocation.location.zip}`}}></span>}
						</address>
						{ directionLink() }
						{ location.birdeyeLocation.phone && <a className={styles.phone} href={`tel:${location.birdeyeLocation.phone}`}>{formatPhone(location.birdeyeLocation.phone)}</a>}
						{ location.ACFLocation.bookMyAppointmentLink && !isAppointmentsPage && <WPLink link={location.ACFLocation.bookMyAppointmentLink} passedClass={styles.link} icon={arrowIconBrightOrange} iconIsSVG={true} />}
						{ location.ACFLocation.bookMyAppointmentLink && isAppointmentsPage && <Link to={location.ACFLocation.bookMyAppointmentLink.url} className={styles.linkappointments} target="_blank">Book now <FixedImage image={arrowRedLocal} passedClass={styles.iconhover} /> { rightArrowGray && <FixedImage image={rightArrowGray} passedClass={styles.icon} />} </Link>}
						{ !location.ACFLocation.bookMyAppointmentLink && location.birdeyeLocation.phone && !isAppointmentsPage && <a className={styles.link} href={`tel:${location.birdeyeLocation.phone}`}>Call Now</a>}
						{ !location.ACFLocation.bookMyAppointmentLink && location.birdeyeLocation.phone && isAppointmentsPage && <a className={styles.linkappointments} href={`tel:${location.birdeyeLocation.phone}`}>Call Now</a>}
					</div>
				</div>
			</div>
		)
		}
	</>
	)
}

export default LocationCard;
